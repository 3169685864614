import moment from 'moment';

interface ICalendarNav {
    activeDate: any;
    setActiveDate: any;
    render: any;
}

const days = [0, 1, 2, 3, 4, 5, 6];

const CalendarNav = ({ activeDate, setActiveDate, render }: ICalendarNav) => {
    return (
        <div className="w-full px-5 grid grid-cols-7 mt-10 mb-2">
            {days.map(day => {
                const currentDate = moment().add(day, 'day');

                return (
                    <div
                        key={`day_${day}`}
                        className="flex justify-center items-center flex-col"
                        onClick={() => setActiveDate(currentDate)}>
                        <span className="text-xs font-inter">{currentDate.format('ddd')}</span>
                        <span
                            className={`w-8 h-8 rounded-full flex justify-center items-center text-sm ${
                                currentDate.isSame(activeDate, 'day')
                                    ? 'bg-primary text-white'
                                    : 'bg-white text-black'
                            }`}>
                            {currentDate.format('D')}
                        </span>
                    </div>
                );
            })}
        </div>
    );
};

export default CalendarNav;
