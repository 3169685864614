import { useCallback, useRef, useState, useEffect } from 'react';
import FullCalendar, {
    DateSpanApi,
    DateSelectArg,
    EventDropArg,
    SlotLabelContentArg,
    CalendarApi,
} from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';

import { createEvent, getEvents, moveEvent } from '@api/events';

import moment from 'moment';
import { tz } from 'moment-timezone';
import { useQuery } from 'react-query';
import { useJwt } from 'react-jwt';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import { useSwipeable } from 'react-swipeable';

import { getToken } from '@utils/accessToken';
import { Spinner } from '@components/Spinner';
import './style.scss';
import { getUser } from '@api/user';

const days = [0, 1, 2, 3, 4, 5, 6];

const Calendar: React.FC = () => {
    let calendarRef: any = useRef();
    let calendarApi: CalendarApi = calendarRef.current?.getApi();
    const [activeDate, setActiveDate] = useState(moment());

    useEffect(() => {
        calendarApi?.gotoDate(activeDate?.format());
    }, [activeDate, calendarApi]);

    const { decodedToken } = useJwt(getToken());

    const slotMinTime = `${tz('2021-06-17 07:00', 'Europe/London').tz(tz.guess()).hour()}:00`;
    const slotMaxTime = `${tz('2021-06-17 18:00', 'Europe/London').tz(tz.guess()).hour()}:00`;

    const {
        isLoading,
        isFetching,
        refetch: refetchCalendarEvents,
        data: calendarEvents,
    } = useQuery('getEvents', getEvents, {
        refetchOnWindowFocus: false,
    });

    const { data: user, refetch: refetchUser } = useQuery('getUser', getUser, {
        refetchOnWindowFocus: false,
    });

    const handleAllowSelect = (selectionInfo: DateSpanApi) => {
        const start = moment(selectionInfo.startStr);
        const end = moment(selectionInfo.endStr);

        if (start.isBefore(moment())) return false;
        if (moment.duration(end.diff(start)).asHours() !== 1) return false;

        return true;
    };

    const handleSelect = useCallback(
        async (info: DateSelectArg) => {
            setTimeout(() => {
                if (user.balance < 1) {
                    toast.warn("Sorry, but you don't have enough lessons on your balance", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    info.view.calendar.unselect();
                    return;
                }
                if (window.confirm('Do you want to book a lesson?')) {
                    createEvent({
                        start: info.startStr,
                        end: info.endStr,
                        username: `${user?.firstname} ${user?.lastname}`,
                        email: decodedToken?.username ?? 'Unknown',
                    }).then(r => {
                        refetchCalendarEvents();
                        refetchUser();
                    });
                }
                info.view.calendar.unselect();
            }, 100);
        },
        [decodedToken?.username, refetchCalendarEvents, user],
    );

    const handleClick = (data: DateClickArg) => {
        calendarApi.select(data.dateStr, moment(data.dateStr).add(1, 'hour').format());
    };

    const handleEventDrop = async (event: EventDropArg) => {
        if (
            moment(event.oldEvent?.startStr).isBefore(moment()) ||
            moment(event.oldEvent?.startStr).diff(moment(), 'hours') < 8
        ) {
            event.revert();
            toast.warn('Sorry, but you can move events not later than 8 hours before the start.', {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (moment(event.event.startStr).isBefore(moment())) {
            event.revert();
            toast.warn("You can't move event to the past. Sorry :(", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (moment(event.event.startStr).diff(moment(), 'hours') < 8) {
            event.revert();
            toast.warn("Sorry, but you can't book a lesson so close to the present moment.", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (window.confirm('Are you sure you want to move this lesson?')) {
            await moveEvent({
                start: event.event.startStr,
                end: event.event.endStr,
                id: event.event._def.publicId,
            });
            refetchCalendarEvents();
        } else {
            event.revert();
            calendarApi.unselect();
        }
    };
    // @ts-ignore
    const events = calendarEvents?.map((event: any) => {
        const isMyEvent = !!event.attendees?.find((p: any) => p.email === decodedToken?.username);
        const result = {
            id: event?.id,
            start: event?.start?.dateTime,
            end: event?.end?.dateTime,
            title: isMyEvent ? 'Your accent class' : 'Busy',
            editable: isMyEvent,
            className: isMyEvent ? 'my' : 'busy',
        };
        if (user?.roles?.includes('ADMIN')) {
            result.title = event.title;
            result.editable = true;
        }

        return result;
    });

    const headersHandler = (args: any) => {
        if (isMobile) {
            return (
                <p className="text-base font-medium font-inter">
                    {moment(args.date).format('dddd')} {moment(args.date).format('D')}{' '}
                    {moment(args.date).format('MMMM')} {moment(args.date).format('YYYY')}
                </p>
            );
        } else {
            return (
                <p className="text-xs font-medium">
                    {moment(args.date).format('ddd')}
                    <br />
                    <span className="text-xl">
                        {moment(args.date).format('D')}{' '}
                        <span className="text-lg">{moment(args.date).format('MMM')}</span>
                    </span>
                </p>
            );
        }
    };

    const handleResize = (event: any) => {
        event.revert();
    };

    const handleSlotLabel = (args: SlotLabelContentArg) => {
        return (
            <span className="font-inter" style={{ fontSize: `${isMobile ? '10px' : '12px'}` }}>
                {moment(args.date).format('h')} {moment(args.date).format('A')}
            </span>
        );
    };

    const handlers = useSwipeable({
        onSwiped: eventData => {
            eventData.dir === 'Right' ? calendarApi.prev() : calendarApi.next();
        },
    });

    // TODO delete event and add it to the balance
    return (
        <div className="relative text-black bg-white rounded-sm calendar">
            {isMobile && (
                <div className="grid w-full grid-cols-7 px-5 mt-10 mb-2" {...handlers}>
                    {days.map(day => {
                        const currentDate = moment().add(day, 'day');
                        return (
                            <div
                                key={`day_${day}`}
                                className="flex flex-col items-center justify-center"
                                onClick={() => setActiveDate(currentDate)}>
                                <span className="text-xs font-inter">
                                    {currentDate.format('ddd')}
                                </span>
                                <span
                                    className={`w-8 h-8 rounded-full flex justify-center items-center text-sm ${
                                        currentDate.isSame(activeDate, 'day')
                                            ? 'bg-primary text-white'
                                            : 'bg-white text-black'
                                    }`}>
                                    {currentDate.format('D')}
                                </span>
                            </div>
                        );
                    })}
                </div>
            )}
            {isFetching || isLoading ? <Spinner /> : null}
            <FullCalendar
                ref={calendarRef}
                plugins={[interactionPlugin, timeGridPlugin]}
                initialView={isMobile ? 'timeGridDay' : 'timeGridWeek'}
                events={events}
                firstDay={moment().day()}
                nowIndicator
                selectable
                selectMinDistance={2}
                eventOverlap={false}
                selectOverlap={false}
                slotMinTime={slotMinTime}
                slotMaxTime={slotMaxTime}
                allDaySlot={false}
                // editable={false}
                height={'85vh'}
                selectAllow={handleAllowSelect}
                eventResize={handleResize}
                eventDrop={handleEventDrop}
                dateClick={handleClick}
                dayHeaderContent={headersHandler}
                customButtons={{
                    myCustomButton: {
                        text: `${tz.guess()}`,
                    },
                }}
                headerToolbar={{
                    start: 'myCustomButton',
                    center: 'title',
                    end: 'today prev,next',
                }}
                slotLabelContent={handleSlotLabel}
                select={handleSelect}
                viewClassNames={`${isFetching || isLoading ? 'opacity-0' : null}`}
            />
        </div>
    );
};

export default Calendar;
