import { getToken } from '@utils/accessToken';
import { makeRequest } from '../makeRequest';

export const buyLessons = async ({
    price,
    title,
    lessons,
    currency,
}: {
    price: string;
    title: string;
    lessons: string;
    currency: string;
}) => {
    const res = await makeRequest({
        url: `${process.env.REACT_APP_API_URL}/api/buy`,
        method: 'POST',
        token: getToken(),
        body: JSON.stringify({
            url: window.location.href,
            price: (Number(price) * 1.029 + 0.3).toFixed(2),
            title: title,
            lessons: lessons,
            currency: currency,
        }),
    });
    window.location.href = res.data.url;
};

export const increaseBalance = async ({
    lessons,
    title,
    price,
}: {
    lessons: string;
    title: string;
    price: string;
}) => {
    const res = await makeRequest({
        url: `${process.env.REACT_APP_API_URL}/api/buy-lesson`,
        method: 'POST',
        token: getToken(),
        body: JSON.stringify({
            lessons,
            title,
            price,
        }),
    });
    return res.data;
};

export const getTransactions = async () => {
    const res = await makeRequest({
        url: `${process.env.REACT_APP_API_URL}/api/transactions`,
        method: 'GET',
        token: getToken(),
    });
    return res.data;
};
