import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { getUser, updateUser, updateUserPassword } from '@api/user';
import { SettingsField } from './Field';

const changePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string()
        .min(4, 'Password length should be more 4 symbols')
        .required('Required'),
    newPassword: Yup.string()
        .min(4, 'Password length should be more 4 symbols')
        .required('Required'),
    newPasswordConfirm: Yup.string().oneOf([Yup.ref('newPassword')], 'Passwords should be same'),
});

export const Settings = () => {
    const { data: user } = useQuery('getUser', getUser, {
        refetchOnWindowFocus: true,
    });

    return (
        <div className="bg-white w-full mt-5 p-4 grid grid-cols-[1.5fr,4fr] msm:grid-cols-1">
            <div className="border-r border-grey-light msm:hidden">
                <div className="w-36 h-36 bg-primary rounded-full mx-auto flex items-center justify-center text-white text-3xl">
                    {user?.firstname?.charAt(0)}
                    {user?.lastname?.charAt(0)}
                </div>
            </div>
            <div className="px-[5%] py-10">
                <Formik
                    initialValues={user}
                    enableReinitialize
                    onSubmit={values => {
                        updateUser(values);
                        toast.success('Your personal data updated successfully', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }}>
                    <Form className="grid grid-cols-2 gap-5 msm:grid-cols-1">
                        <SettingsField name="firstname" label="First name" />
                        <SettingsField name="jobTitle" label="Profession" />
                        <SettingsField name="lastname" label="Last name" />
                        <SettingsField name="country" label="Country of residence" />
                        <SettingsField name="age" label="Age" />
                        <div className="flex items-end">
                            <button
                                className="bg-primary text-white rounded-lg h-9 ml-auto px-10"
                                type="submit">
                                Save personal data
                            </button>
                        </div>
                    </Form>
                </Formik>
                <hr className="text-grey-light my-10" />
                <Formik
                    initialValues={{
                        oldPassword: '',
                        newPassword: '',
                        newPasswordConfirm: '',
                    }}
                    onSubmit={async (values, { resetForm }) => {
                        const res = await updateUserPassword({
                            newPassword: values.newPassword,
                            oldPassword: values.oldPassword,
                        });

                        if (res.status) {
                            toast.success('Your password updated successfully', {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                            resetForm();
                        } else
                            toast.error(res.error, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                    }}
                    validationSchema={changePasswordSchema}>
                    <Form className="grid grid-cols-4 gap-5 msm:grid-cols-1">
                        <SettingsField name="oldPassword" label="Old Password" type="password" />
                        <SettingsField name="newPassword" label="New Password" type="password" />
                        <SettingsField
                            name="newPasswordConfirm"
                            label="Confirm Password"
                            type="password"
                        />
                        <button
                            className="bg-primary text-white rounded-lg h-9 mt-auto"
                            type="submit">
                            Change
                        </button>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};
