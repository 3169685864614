import { useEffect, useState } from 'react';

import { ErrorMessage, Field as Input, getIn } from 'formik';

type FieldType = {
    name: string;
    placeholder?: string;
    type?: string;
    label?: string;
    errors?: any;
    touched?: any;
    classnames?: string;
    as?: string;
    setCurrency?: (value: any) => void;
};

export const Field = ({
    name,
    placeholder,
    type = '',
    label,
    errors,
    touched,
    classnames,
    as,
    setCurrency,
}: FieldType) => {
    const [error, setError] = useState(false);

    useEffect(() => {
        setError(getIn(errors, name) && getIn(touched, name));
    }, [errors, name, touched]);

    return (
        <div
            className={`border-b h-10 w-full border-grey-light focus:border-black outline-none transition-all relative flex justify-between items-center ${
                as === 'textarea' && 'h-full'
            } ${classnames}`}>
            {label && (
                <label htmlFor={name} className="">
                    {label}
                </label>
            )}
            <Input
                id={name}
                className={` ${error && 'border-error'} outline-none w-full pl-4`}
                as={as}
                type={type}
                name={name}
                placeholder={placeholder}
                style={{ background: 'none', borderRadius: '8px' }}
            />
            {setCurrency && (
                <Input
                    as="select"
                    name="currency"
                    onChange={(e: any) => setCurrency(e.target.value)}
                    className="h-full border-l border-grey-light outline-none text-grey-dark pl-2 pr-1 font-medium">
                    <option value="USD">$</option>
                    <option value="EUR">€</option>
                    <option value="GBP">£</option>
                </Input>
            )}
            <ErrorMessage
                component="span"
                name={name}
                className="text-xs text-error absolute left-0 -bottom-5"
            />
        </div>
    );
};
