import styles from './spinner.module.scss';
type SpinnerType = {
    className?: string;
};

export const Spinner = ({ className }: SpinnerType) => {
    return (
        <div
            className={`w-full h-full flex justify-center items-center z-50 absolute overflow-hidden top-0 ${className}`}>
            <div className={styles.loader}>Loading...</div>
        </div>
    );
};
