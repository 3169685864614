export const storage = {
    set: (title: string, data: string) => {
        return localStorage.setItem(title, data);
    },
    get: (title: string): string => {
        let token;
        if (localStorage.getItem(title)) {
            token = localStorage.getItem(title);
        } else {
            token = ''
        }
        // @ts-ignore
        return token;
    },
    remove: (title: string) => {
        return localStorage.removeItem(title);
    }
}