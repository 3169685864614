import { Field } from '@uikit/Fields';
import { Formik, Form } from 'formik';

import { editUser, getUser } from '@api/user';
import { useQuery } from 'react-query';

import styles from './edit.module.scss';
import { Button } from '@uikit/Button';
import { SettingsField } from '../../Settings/Field';
import { IUser } from 'types/dto';

export const EditModal = ({ data, onClose }: { data?: IUser; onClose: () => void }) => {
    return (
        <div className={styles.editContainer}>
            <div className={styles.peoplePhoto}>
                <img src={`../peoplePhoto/${data?.image}`} alt="peopleImages" />
            </div>
            <div className={styles.editTitle}>{`${data?.firstname} ${data?.lastname}`}</div>
            <Formik
                initialValues={{
                    nickname: data?.nickname,
                    firstname: data?.firstname,
                    username: data?.username,
                    lastname: data?.lastname,
                    rate: data?.rate,
                    currency: data?.currency,
                    country: data?.country,
                    balance: data?.balance,
                    link: data?.link,
                }}
                onSubmit={async values => {
                    editUser({
                        _id: data?._id,
                        nickname: values.nickname,
                        firstname: values.firstname,
                        username: values.username,
                        lastname: values.lastname,
                        rate: values.rate,
                        country: values.country,
                        balance: values.balance,
                        currency: values.currency,
                        link: values.link,
                    }).then(res => {
                        onClose();
                    });
                }}>
                {({ setFieldValue }) => (
                    <Form>
                        <div className="grid grid-cols-2 gap-7">
                            <SettingsField type="text" name="nickname" label="Nickname" />
                            <SettingsField type="text" name="firstname" label="First name" />
                            <SettingsField type="text" name="username" label="E-mail" />
                            <SettingsField type="text" name="lastname" label="Last name" />
                            <SettingsField type="text" name="country" label="Country" />
                            <SettingsField type="number" name="balance" label="Balance" />
                            <Field
                                type="text"
                                name="rate"
                                placeholder={`${data?.rate}`}
                                classnames={styles.Field}
                                setCurrency={value => setFieldValue('currency', value)}
                            />
                            <SettingsField type="text" name="link" label="link" />
                        </div>
                        <div className="w-full flex justify-center mt-4">
                            <Button title="Update user" />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
