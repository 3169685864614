import { NavLink } from 'react-router-dom';

import { deleteToken } from '@utils/accessToken';
import styles from './navbar.module.scss';

import home from './images/home.svg';
import files from './images/files.svg';
import payment from './images/payment.svg';
import calendar from './images/calendar.svg';
import settings from './images/settings.svg';
import exit from './images/exit.svg';
import logo from './images/logo.svg';
import admin from './images/admin.svg';
import { useRoleAuth } from '@utils/useRoleAuth';

import { getUser } from '@api/user';
import { useQuery } from 'react-query';

export const Navbar = () => {
    const { isAdmin } = useRoleAuth();
    const { data: user, refetch } = useQuery('getUser', () => getUser(), {
        refetchOnWindowFocus: false,
    });
    return (
        <div className="h-full mmd:hidden m3xl:flex ">
            <nav className={styles.nav}>
                <div className={styles.logo}>
                    <NavLink to="/">
                        <img src={logo} alt="" />
                    </NavLink>
                </div>
                <div className={styles.content}>
                    <ul className={styles.links}>
                        <li>
                            <NavLink exact to="/profile/dashboard" activeClassName={styles.active}>
                                <img
                                    className="border border-grey-dark p-2.5 rounded-xl mr-3 w-4 h-4 box-content flex justify-center items-center"
                                    src={home}
                                    alt=""
                                />
                                Dashboard
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact to="/profile/calendar" activeClassName={styles.active}>
                                <img
                                    className="border border-grey-dark p-2.5 rounded-xl mr-3 w-4 h-4 box-content flex justify-center items-center"
                                    src={calendar}
                                    alt=""
                                />
                                Calendar
                            </NavLink>
                        </li>
                        <li className={!user?.link ? styles.disabled : ''}>
                            <a
                                href={user?.link ? user.link : '/#'}
                                target="_blank"
                                rel="noreferrer">
                                <img
                                    className="border border-grey-dark p-2.5 rounded-xl mr-3 w-4 h-4 box-content flex justify-center items-center"
                                    src={files}
                                    alt=""
                                />
                                Files
                            </a>
                        </li>
                        <li className={styles.active}>
                            <NavLink to="/profile/payment" activeClassName={styles.active}>
                                <img
                                    className="border border-grey-dark p-2.5 rounded-xl mr-3 w-4 h-4 box-content flex justify-center items-center"
                                    src={payment}
                                    alt=""
                                />
                                Payment
                            </NavLink>
                        </li>
                        {isAdmin && (
                            <li>
                                <NavLink to="/profile/admin" activeClassName={styles.active}>
                                    <img
                                        className="border border-grey-dark p-2.5 rounded-xl mr-3 w-4 h-4 box-content flex justify-center items-center"
                                        src={admin}
                                        alt=""
                                    />
                                    Manage users
                                </NavLink>
                            </li>
                        )}
                        {isAdmin && (
                            <li>
                                <NavLink to="/profile/invite" activeClassName={styles.active}>
                                    <img
                                        className="border border-grey-dark p-2.5 rounded-xl mr-3 w-4 h-4 box-content flex justify-center items-center"
                                        src={admin}
                                        alt=""
                                    />
                                    Invitation
                                </NavLink>
                            </li>
                        )}
                        <li>
                            <NavLink to="/profile/settings" activeClassName={styles.active}>
                                <img
                                    className="border border-grey-dark p-2.5 rounded-xl mr-3 w-4 h-4 box-content flex justify-center items-center"
                                    src={settings}
                                    alt=""
                                />
                                Settings
                            </NavLink>
                        </li>
                        <li>
                            <a
                                href="/"
                                onClick={() => {
                                    deleteToken();
                                }}>
                                <img
                                    className="border border-grey-dark p-2.5 rounded-xl mr-3 w-4 h-4 box-content flex justify-center items-center"
                                    src={exit}
                                    alt=""
                                />
                                Exit
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    );
};
