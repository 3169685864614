import { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { signIn } from "@api/auth";

import { Field } from "@uikit/Fields";
import { Button } from "@uikit/Button";

export const Login = () => {
  const [error, setError] = useState("");

  return (
    <div className="z-50 w-470 px-14 py-12 bg-white cursor-default">
      <p className="text-2xl">
        Login or{" "}
        <Link className="underline text-primary hover:text-primary hover:opacity-70 transition-all" to="/?auth=signup">
          Signup
        </Link>
      </p>
      <p className="text-base text-error mt-2">{error}</p>

      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={(values, { setValues }) => {
          signIn({
            username: values.email,
            password: values.password,
          }).then((res) => {
            setError(res.error);
            !res.status &&
              setValues({
                email: values.email,
                password: "",
              });
          });
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email("Invalid email").required("Required"),
          password: Yup.string().required("Required"),
        })}
      >
        {({ errors, touched }) => (
          <Form className="space-y-11 mt-11">
            <Field name="email" placeholder="Email" type="email" errors={errors} touched={touched} />
            <Field name="password" type="password" placeholder="Password" errors={errors} touched={touched} />
            <div className="flex items-center">
              {/* <Link to={"?auth=reset"} className="hover:text-primary transition-all text-base">
                Forgot password?
              </Link> */}
              <Button title="Login" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
