import { fillProfile } from '@api/user';
import { Button } from '@uikit/Button';
import { Field } from '@uikit/Fields';
import { getToken } from '@utils/accessToken';
import { Form, Formik } from 'formik';
import { useJwt } from 'react-jwt';
import * as Yup from 'yup';

export const ProfileForm = () => {
    const { decodedToken } = useJwt(getToken());

    return (
        <div className="w-full h-screen fixed left-0 top-0 backdrop-filter backdrop-blur z-50 flex justify-center items-center bg-black bg-opacity-20">
            <div className="bg-white container w-1080 py-16 px-14">
                {/* TODO form hiding */}
                <p className="text-primary text-2xl">
                    To continue please complete the user profile
                </p>
                <p className="text-base mt-3">The form should be completed in English</p>
                <Formik
                    initialValues={{
                        firstname: '',
                        lastname: '',
                        age: '',
                        language: '',
                        country: '',
                        job: '',
                        description: '',
                    }}
                    onSubmit={({
                        age,
                        country,
                        description,
                        firstname,
                        job,
                        language,
                        lastname,
                    }) => {
                        fillProfile({
                            age,
                            country,
                            description,
                            firstname,
                            job,
                            language,
                            lastname,
                            username: decodedToken.username,
                        }).then(() => {
                            window.location.reload();
                        });
                    }}
                    validationSchema={Yup.object().shape({
                        firstname: Yup.string().required('Required'),
                        lastname: Yup.string().required('Required'),
                        age: Yup.string().required('Required'),
                        language: Yup.string().required('Required'),
                        country: Yup.string().required('Required'),
                        job: Yup.string().required('Required'),
                        description: Yup.string(),
                    })}>
                    {() => (
                        <Form className="space-y-11 mt-11">
                            <div className="grid grid-cols-2 gap-x-16">
                                <div className="space-y-10">
                                    <Field name="firstname" placeholder="First name" />
                                    <Field name="lastname" placeholder="Last name" />
                                    <Field name="age" placeholder="Age" />
                                    <Field name="language" placeholder="Native language" />
                                    <Field name="country" placeholder="Country of residence" />
                                    <Field name="job" placeholder="Profession" />
                                </div>
                                <div className="flex flex-col h-full space-y-8">
                                    <div className="border border-grey-light px-8 py-4 h-full">
                                        <Field
                                            name="description"
                                            as="textarea"
                                            placeholder="A few words about yourself and your language goals"
                                            classnames="border-none h-full"
                                        />
                                    </div>
                                    <div className="flex justify-end">
                                        <Button title="Continue" type="submit" />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};
