import styles from './history.module.scss';
import { format } from 'date-fns';
import { getUser } from '@api/user';
import { useQuery } from 'react-query';
import { currencies } from '@utils/currency';
interface ISelectHistory {
    transactions: IHistory[];
}

interface IHistory {
    created_at: Date;
    title: string;
    total: number;
}

export const History = ({ transactions }: ISelectHistory) => {
    const { data: user } = useQuery('getUser', () => getUser(), {
        refetchOnWindowFocus: false,
    });

    return (
        <div className={styles.history}>
            <h2 className={styles.heading}>Payment history</h2>
            <div className={styles.historyTitle}>
                <div className={styles.historyDate}>Date</div>
                <div className={styles.historyVendor}>Vendor</div>
                <div className={styles.historyAmount}>Amount</div>
            </div>
            {transactions.map((transaction: IHistory, index) => (
                <div className={styles.transactionHistory} key={`num_${index}`}>
                    <div className={styles.transactionDate}>
                        <span className={styles.date}>
                            {format(new Date(transaction.created_at), 'dd MMM HH:mm')}
                        </span>
                    </div>
                    <div className={styles.transactionVendor}>
                        <img src="/roundLogoSnake.svg" alt="LogoSnake" />
                        <div className={styles.transactionData}>
                            <h2>The Accent Doctor</h2>
                            <p>{transaction.title}</p>
                        </div>
                    </div>
                    <div className={styles.transactionAmount}>
                        {currencies[user?.currency]} {transaction.total}
                    </div>
                </div>
            ))}
            {transactions.length == 0 && <p className={styles.empty}>No transactions yet</p>}
        </div>
    );
};
