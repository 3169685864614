import { useEffect, useState } from "react";

import { checkToken } from "@api/auth";

export const useAuthorized = () => {
  const [authorized, setAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function checkTokenHook() {
      try {
        const token = await checkToken();
        // @ts-ignore
        setAuthorized(token);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    checkTokenHook();
  }, []);

  return {
    authorized,
    loading,
  };
};
