import { getToken } from '@utils/accessToken';
import { makeRequest } from '../makeRequest';
import { refetchToken } from './auth';

type ProfileType = {
    firstname: string;
    lastname: string;
    age: string;
    country: string;
    job: string;
    description: string;
    username: string;
    language: string;
};

export const fillProfile = async ({
    age,
    country,
    description,
    firstname,
    job,
    lastname,
    language,
    username,
}: ProfileType) => {
    const res = await makeRequest({
        url: `${process.env.REACT_APP_API_URL}/api/user`,
        method: 'PUT',
        body: JSON.stringify({
            age,
            country,
            description,
            firstname,
            job,
            lastname,
            language,
            username,
        }),
    });
    await refetchToken();
    return res.data;
};

export const takeAllPeople = async () => {
    const res = await makeRequest({
        url: `${process.env.REACT_APP_API_URL}/api/users`,
        method: 'GET',
    });
    return res.data;
};

export const setUserRate = async ({
    username,
    rate,
    currency,
}: {
    username: string;
    rate: any;
    currency: any;
}) => {
    const res = await makeRequest({
        url: `${process.env.REACT_APP_API_URL}/api/user-rate`,
        method: 'POST',
        body: JSON.stringify({
            username,
            rate,
            currency,
        }),
    });
    return res.data;
};

export const getUser = async () => {
    const res = await makeRequest({
        url: `${process.env.REACT_APP_API_URL}/api/user`,
        method: 'GET',
        token: getToken(),
    });
    return res.data;
};

export const deleteUser = async ({ username }: { username: string }) => {
    const res = await makeRequest({
        url: `${process.env.REACT_APP_API_URL}/api/user/delete`,
        method: 'POST',
        token: getToken(),
        body: JSON.stringify({
            username,
        }),
    });
    return res.data;
};

export const inviteUser = async ({ email, rate, name, balance, currency }: any) => {
    const res = await makeRequest({
        url: `${process.env.REACT_APP_API_URL}/api/user-invite`,
        method: 'POST',
        token: getToken(),
        body: JSON.stringify({
            username: email,
            rate,
            nickname: name,
            balance,
            currency,
        }),
    });
    return res.data;
};

export const updateUser = async ({
    username,
    age,
    firstname,
    lastname,
    country,
    jobTitle,
}: any) => {
    const res = await makeRequest({
        url: `${process.env.REACT_APP_API_URL}/api/user`,
        method: 'POST',
        token: getToken(),
        body: JSON.stringify({
            username: username,
            properties: {
                age: age,
                firstname: firstname,
                lastname: lastname,
                country: country,
                jobTitle: jobTitle,
            },
        }),
    });
    return res.data;
};

export const editUser = async ({
    nickname,
    firstname,
    username,
    lastname,
    rate,
    country,
    balance,
    currency,
    _id,
    link,
}: any) => {
    const res = await makeRequest({
        url: `${process.env.REACT_APP_API_URL}/api/user`,
        method: 'POST',
        token: getToken(),
        body: JSON.stringify({
            _id: _id,
            properties: {
                nickname: nickname,
                firstname: firstname,
                lastname: lastname,
                rate: rate,
                country: country,
                balance: balance,
                currency: currency,
                username: username,
                link: link,
            },
        }),
    });
    return res.data;
};

export const updateUserPassword = async ({
    oldPassword,
    newPassword,
}: {
    oldPassword: string;
    newPassword: string;
}) => {
    const res = await makeRequest({
        url: `${process.env.REACT_APP_API_URL}/api/user/password`,
        method: 'POST',
        token: getToken(),
        body: JSON.stringify({
            oldPassword,
            newPassword,
        }),
    });
    return res.data;
};
