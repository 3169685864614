import { storage } from "./storage";

export const getToken = () => {
  return storage.get("token");
};

export const deleteToken = () => {
  storage.remove("token");
};

export const setToken = (token: string) => {
  storage.set("token", token);
};
