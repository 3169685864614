import { Redirect, useHistory } from "react-router-dom";

import { Login } from "./Login";
import { SignUp } from "./Signup";
// import { Reset } from "./Reset";
// import { ResetForm } from "./ResetForm";
// import { Sent } from "./Sent";

import { GET_ENUMS } from "./config";
import { useGetPopupState } from "./hooks/useGetPopupState";

import { useAuthorized } from "@utils/useAuthorized";
import { Spinner } from "@components/Spinner";

export const Auth = () => {
  const history = useHistory();
  const { authorized, loading } = useAuthorized();
  const popups = {
    [GET_ENUMS.popup.signIn]: Login,
    [GET_ENUMS.popup.signUp]: SignUp,
    // [GET_ENUMS.popup.reset]: Reset,
    // [GET_ENUMS.popup.resetForm]: ResetForm,
    // [GET_ENUMS.popup.sent]: Sent,
  };
  const { mountedPopup, isOpened } = useGetPopupState();

  // ignoring mounted popup can't be null
  // @ts-ignore
  const Component = popups[mountedPopup];

  if (!Component) {
    return null;
  }

  if (loading) {
    return <Spinner />;
  }

  if (authorized) {
    return <Redirect to="/profile/dashboard" />;
  }

  return (
    <div className="absolute w-full h-full top-0 flex justify-center items-center">
      <div className="z-10 absolute w-full h-full top-0 bg-black bg-opacity-50 cursor-pointer" onClick={() => history.push("/")}></div>
      <Component isOpened={isOpened} />
    </div>
  );
};
