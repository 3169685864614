export const GET_PARAMS = {
	popup: 'auth',
};
export const GET_ENUMS = {
	popup: {
		signIn: 'login',
		signUp: 'signup',
		reset: 'reset',
		sent: 'sent',
		resetForm: 'resetform',
	},
};
