import { ErrorMessage, Field } from 'formik';

interface ISettingsField {
    name: string;
    type?: string;
    label?: string;
}

export const SettingsField = ({ name, type = 'text', label }: ISettingsField) => {
    return (
        <div className="relative">
            <label htmlFor={name} className="mb-1 text-[13px] opacity-50">
                {label}
            </label>
            <Field
                className="border border-grey-light rounded-lg h-9 w-full px-2 outline-none"
                name={name}
                id={name}
                type={type}
            />
            <ErrorMessage
                component="span"
                name={name}
                className="text-xs text-error absolute top-full mt-1 left-0 w-full"
            />
        </div>
    );
};
