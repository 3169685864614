import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Navbar } from './components/Navbar';
import { Header } from './components/Header';
import styles from './profile.module.scss';

import Calendar from './Calendar';
import MobileCalendar from './Calendar/mobile';

import Dashboard from './Dashboard';
import { isMobile } from 'react-device-detect';

import { Payment } from './Payment';
import { Admin } from './Admin';
import { PrivateRoute } from '@utils/ProtectedRoute';
import { Settings } from './Settings';
import { InviteStudent } from './inviteStudent';

export const userProfile = () => {
    return (
        <>
            <div className={styles.container}>
                <Navbar />
                <div className={styles.content}>
                    <Header />
                    <Switch>
                        <Route exact path="/profile/calendar">
                            {isMobile ? <MobileCalendar /> : <Calendar />}
                        </Route>
                        <Route exact path="/profile/dashboard">
                            <Dashboard />
                        </Route>
                        <Route exact path="/profile/payment">
                            <Payment />
                        </Route>
                        <Route exact path="/profile/settings">
                            <Settings />
                        </Route>
                        <Route exact path="/profile/admin">
                            <PrivateRoute Component={Admin} admin={true} />
                        </Route>
                        <Route exact path="/profile/invite">
                            <InviteStudent />
                        </Route>
                    </Switch>
                </div>
            </div>
            <ToastContainer />
        </>
    );
};
