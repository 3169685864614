import axios from "axios";

type Request = {
    url: string,
    method: 'GET' | 'POST' | 'PUT',
    token?: String | null,
    body?: any,
}

export const makeRequest = ({url, method, token, body }: Request) => {
    let settings = {
        url: url,
        method: method,
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`
        },
        data: body,
    };
    return axios(settings)

};
