import './style.scss';

export const Button = ({ title = '', classnames = '' }: any) => {
    return (
        <button
            className={`text-sm lg:text-base py-2 px-8 border text-black font-medium
             border-black hover:border-primary hover:text-primary active:bg-primary 
             active:text-white active:border-primary transition-all ${classnames}`}>
            {title}
        </button>
    );
};
