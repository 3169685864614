import { useRef, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { useJwt } from 'react-jwt';

import { GetNextEvent, GetNextEventAdmin } from '@api/events';
import { getToken } from '@utils/accessToken';
import { Button } from '@uikit/Button';
import { useState } from 'react';
import moment from 'moment';
import { Spinner } from '@components/Spinner';
import { useRoleAuth } from '@utils/useRoleAuth';

const Dashboard = () => {
    const {isAdmin} = useRoleAuth()
    const tooltip = useRef(null);
    const [nextLesson, setNextLesson] = useState<any>({ start: { dateTime: '' } });
    const [loading, setLoading] = useState(true);
    const { decodedToken } = useJwt(getToken());

    useEffect(() => {
        console.log(decodedToken);
        if (decodedToken?.username) {
            if (isAdmin) {
                GetNextEventAdmin(decodedToken?.username).then(res => {
                    setNextLesson(res);
                    setLoading(false);
                });
            } else {
                GetNextEvent(decodedToken?.username).then(res => {
                    setNextLesson(res);
                    setLoading(false);
                });

            }
        }
    }, [decodedToken]);
    
    return (
        <>
            {loading && (
                <div className="flex items-center justify-center w-full h-screen bg-white">
                    <Spinner />
                </div>
            )}
            <div className="p-5 mx-4 mt-5 bg-white border border-black sm:w-96 md:border-none">
                <div className="flex justify-between">
                    <p className="text-xl font-medium lg:text-2xl">
                        {nextLesson ? 'The next lesson' : "Let's book your next lesson"}
                    </p>
                    <span className="text-lg">
                        {nextLesson
                            ? moment(nextLesson.start.dateTime).format('dddd') ===
                              moment().format('dddd')
                                ? `Today ${moment(nextLesson.start.dateTime).format('HH:mm')}`
                                : `${moment(nextLesson.start.dateTime).format('dddd')} ${moment(
                                      nextLesson.start.dateTime,
                                  ).format('HH:mm')}`
                            : null}
                    </span>
                </div>
                <div className={`${nextLesson ? null : 'hidden'}`}>
                    <p className={`text-sm lg:text-base mt-8 font-medium`}>
                        Zoom ID:{' '}
                        <span
                            className="font-light transition-all cursor-pointer hover:opacity-50"
                            data-tip="Copied to clipboard"
                            data-event="click">
                            679 577 0229
                        </span>
                    </p>
                    <p className="mt-2 text-sm font-medium lg:text-base">
                        Password:{' '}
                        <span
                            className="font-light transition-all cursor-pointer hover:opacity-50"
                            data-tip="Copied to clipboard"
                            data-event="click">
                            gx7duY
                        </span>
                    </p>
                </div>
                <div className="flex justify-end mt-8 space-x-3">
                    <Link to="/profile/calendar">
                        <Button title={`${nextLesson ? 'Move' : 'Book now'}`} />
                    </Link>
                    <a
                        className={`${nextLesson ? null : 'hidden'}`}
                        href="https://zoom.us/j/6795770229?pwd=YTJydmdUTmpVQU9XZjh6dEwzOE5wUT09"
                        target="_blank"
                        rel="noreferrer">
                        <Button title="Join now" />
                    </a>
                </div>
            </div>
            <ReactTooltip
                afterShow={e => {
                    navigator.clipboard.writeText(e.path[0].textContent);
                    setTimeout(() => {
                        ReactTooltip.hide();
                    }, 1500);
                }}
                ref={tooltip}
            />
        </>
    );
};

export default Dashboard;
