import { inviteUser } from '@api/user';
import { Field } from '@uikit/Fields';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import styles from './invite.module.scss';

export const InviteStudent = () => {
    return (
        <div className={`bg-white p-5 rounded-2xl mt-5 w-[80%] my-0 mx-auto mmd:w-full mmd:p-1`}>
            <h2 className={styles.modalTitle}>Invite a student</h2>
            <Formik
                initialValues={{
                    email: '',
                    rate: '',
                    name: '',
                    balance: '',
                    currency: '',
                    link: '/',
                }}
                onSubmit={async values => {
                    await inviteUser(values);
                    toast.success('User successfully invited', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Invalid email').required('Required'),
                    rate: Yup.number().required('Required'),
                    name: Yup.string().required('Required'),
                    balance: Yup.number().required('Required'),
                    currency: Yup.string(),
                })}>
                {({ errors, touched, setFieldValue }) => (
                    <Form>
                        <div className={styles.modalGrid}>
                            <div className={styles.modalItem}>
                                <p>Student email</p>
                                <Field
                                    type="email"
                                    name="email"
                                    errors={errors}
                                    touched={touched}
                                    classnames={styles.input}
                                />
                            </div>
                            <div className={styles.modalItem}>
                                <p>Student rate</p>
                                <Field
                                    type="number"
                                    name="rate"
                                    placeholder={'0'}
                                    errors={errors}
                                    touched={touched}
                                    classnames={styles.input}
                                    setCurrency={value => setFieldValue('currency', value)}
                                />
                            </div>
                            <div className={styles.modalItem}>
                                <p>Student nickname</p>
                                <Field
                                    type="text"
                                    name="name"
                                    errors={errors}
                                    touched={touched}
                                    classnames={styles.input}
                                />
                            </div>
                            <div className={styles.modalItem}>
                                <p>Student balance</p>
                                <Field
                                    type="number"
                                    name="balance"
                                    placeholder={'0'}
                                    errors={errors}
                                    touched={touched}
                                    classnames={styles.input}
                                />
                            </div>
                            <div className={styles.modalItem}>
                                <p>Student google drive link</p>
                                <Field
                                    type="text"
                                    name="link"
                                    errors={errors}
                                    touched={touched}
                                    classnames={styles.input}
                                />
                            </div>
                        </div>
                        <div className={styles.inviteButtonBox}>
                            <button className={styles.inviteButton}>Send</button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
