import { useEffect, useState, PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

interface IModal {
    show: boolean;
    onClose: () => void;
}

export const Modal = ({ show, onClose, children }: PropsWithChildren<IModal>) => {
    const [isBrowser, setIsBrowser] = useState(false);

    useEffect(() => {
        setIsBrowser(true);
    }, []);

    const handleCloseClick = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        onClose();
    };

    const modalContent = show ? (
        <StyledModalOverlay>
            <StyledModal>
                <StyledModalHeader>
                    <a href="#" onClick={handleCloseClick}>
                        x
                    </a>
                </StyledModalHeader>
                <StyledModalBody>{children}</StyledModalBody>
            </StyledModal>
        </StyledModalOverlay>
    ) : null;
    if (isBrowser) {
        return ReactDOM.createPortal(
            modalContent,
            document.getElementById('modal-root') as HTMLElement,
        );
    } else {
        return null;
    }
};

const StyledModalBody = styled.div``;

const StyledModalHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    font-size: 25px;
`;

const StyledModal = styled.div`
    background: white;
    border-radius: 18px;
    padding: 20px 32px;
    margin: 0 15px;
`;
const StyledModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow: auto;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
`;
