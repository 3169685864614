import { getToken, setToken } from '../../utils/accessToken';
import { makeRequest } from '../makeRequest';

export const signUp = async ({ username = '', password = '', secureCode = '' }) => {
    const res = await makeRequest({
        url: `${process.env.REACT_APP_API_URL}/auth/signup`,
        method: 'POST',
        body: JSON.stringify({
            username,
            password,
            secureCode,
        }),
    });

    if (!!res.data.status) {
        setToken(res.data.token);
        window.location.pathname = '/profile/dashboard';
    }

    return res.data;
};

export const signIn = async ({ username = '', password = '' }) => {
    const res = await makeRequest({
        url: `${process.env.REACT_APP_API_URL}/auth/login`,
        method: 'POST',
        body: JSON.stringify({
            username,
            password,
        }),
    });

    if (!!res.data.status) {
        setToken(res.data.token);
        window.location.pathname = '/profile/dashboard';
    }

    return res.data;
};

export const checkToken = async () => {
    const authorized = await makeRequest({
        url: `${process.env.REACT_APP_API_URL}/auth/check`,
        method: 'GET',
        token: getToken(),
    });

    return authorized.data;
};

export const refetchToken = async () => {
    const token = await makeRequest({
        url: `${process.env.REACT_APP_API_URL}/auth/token`,
        method: 'GET',
        token: getToken(),
    });
    setToken(token.data);
    return true;
};
