import { useState } from 'react';
import { Auth } from './Auth';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useAuthorized } from '@utils/useAuthorized';

import { signUp } from '@api/auth';

import { Field } from '@uikit/Fields';
import { Button } from '@uikit/Button';
import { Link } from 'react-router-dom';

import logo from './assets/img/logotype.svg';
import contactPhoto from './assets/img/photo.jpeg';
import facebook from './assets/img/facebook.svg';
import twitter from './assets/img/twitter.svg';
import insta from './assets/img/insta.svg';
import facebookWhite from './assets/img/facebookWhite.svg';
import twitterWhite from './assets/img/twitterWhite.svg';
import instaWhite from './assets/img/instaWhite.svg';
import facebookMobile from './assets/img/facebookMobile.svg';
import twitterMobile from './assets/img/twitterMobile.svg';
import instaMobile from './assets/img/instaMobile.svg';
import blueArrow from './assets/img/blueArrowTop.svg';

import styles from './landing.module.scss';

export const Website = () => {
    const [error, setError] = useState('');
    const { authorized } = useAuthorized();
    return (
        <>
            <header className={styles.header}>
                <div className={styles.containerHeader}>
                    <div className={styles.navigation}>
                        <a href="/" className={styles.logoBox}>
                            <img src={logo} alt="logotype" />
                        </a>
                        <Link
                            className={styles.login}
                            to={authorized ? `/profile/dashboard` : '?auth=login'}>
                            {authorized ? 'My profile' : 'Login'}
                        </Link>
                    </div>
                    <h1 className={styles.pronunciation}>
                        Perfect English pronunciation. <br />
                        Accent adjustment. <br />
                        Fluency coaching.
                    </h1>
                    <p className={styles.name}>Charles Hendley</p>
                    <p className={styles.work}>The Accent Doctor</p>
                </div>
            </header>
            <section className={styles.description}>
                <div className={styles.descFlex}>
                    <div className={styles.features}>
                        <div>
                            <p className={styles.featuresCount}>10</p>
                            <p className={styles.featuresText}>
                                Unique <br /> Teaching <br /> Features
                            </p>
                        </div>
                    </div>
                    <div className={styles.advantages}>
                        <div className={styles.advantagesTitle}>
                            {/* <p className={styles.largeTitle}>The Accent Doctor </p> */}
                            <p className={styles.smallTitle}>
                                An exceptional accent course that really does get results.
                            </p>
                        </div>
                        <ul>
                            <li>100% original method</li>
                            <li>Intense 55 minute sessions</li>
                            <li>Personalised just for you</li>
                            <li>No IPA or jargon</li>
                            <li>Bespoke teaching material </li>
                            <li>Full record of each class</li>
                            <li>Shared online folder.</li>
                            <li>Current 2022 English usage</li>
                            <li>Intuitive and easy-to-understand</li>
                            <li>Fascinating, stimulating, intelligent</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section id="register" className={styles.register}>
                <div className={styles.registerFlex}>
                    <div className={styles.instruction}>
                        <p className={styles.instructionTitle}>
                            Create an account, or book a trial class, in five simple steps.
                        </p>
                        <ol>
                            <li>Complete the registration form.</li>
                            <li>Respond to the invitation link sent to your email address.</li>
                            <li>Fill in the user profile.</li>
                            <li>Book a $20 trial class on our calendar.</li>
                            <li>Or go straight ahead and book your accent course!</li>
                        </ol>
                    </div>
                    <div className={styles.registration}>
                        <div className={styles.registrationTitle}>Register here.</div>
                        <Formik
                            initialValues={{
                                email: '',
                                password: '',
                            }}
                            onSubmit={async (values, { setValues }) => {
                                signUp({
                                    username: values.email,
                                    password: values.password,
                                }).then(res => {
                                    setError(res.error);
                                    window.location.href =
                                        'https://theaccentdoctor.com/?auth=login';
                                    !res.status &&
                                        setValues({
                                            email: values.email,
                                            password: values.password,
                                        });
                                });
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string()
                                    .email('Enter correct email')
                                    .required('Required'),
                                password: Yup.string()
                                    .min(4, 'Password length should be more 4 symbols')
                                    .required('Required'),
                            })}>
                            {({ errors, touched }) => (
                                <Form>
                                    <div>
                                        <Field
                                            name="email"
                                            placeholder="E-mail"
                                            type="email"
                                            errors={errors}
                                            touched={touched}></Field>
                                    </div>
                                    <div>
                                        <Field
                                            name="password"
                                            placeholder="Password"
                                            type="password"
                                            errors={errors}
                                            touched={touched}></Field>
                                    </div>
                                    <div className={styles.formButton}>
                                        <Button title="Register" />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </section>
            <section className={styles.contact}>
                <div className={styles.contactFlex}>
                    <div className={styles.contactPhotoParth}>
                        <img
                            src={contactPhoto}
                            alt="contactPhoto"
                            style={{ objectFit: 'contain' }}
                        />
                    </div>
                    <div className={styles.contactBox}>
                        <p className={styles.contactTitle}>Contact</p>
                        <div className={styles.contactData}>
                            <h3>Charles Hendley</h3>
                            <p className={styles.country}>london</p>
                            <a href="https://wa.me/+447508813891" className={styles.whatsappLink}>
                                Whatsapp
                            </a>
                            <p> info@theaccentdoctor.com</p>
                            {/* <div className={styles.socialIcons}>
                                <div>
                                    <a href="#">
                                        <img src={facebook} alt="facebook" />
                                    </a>
                                    <a href="#">
                                        <img src={twitter} alt="twitter" />
                                    </a>
                                    <a href="#">
                                        <img src={insta} alt="instagram" />
                                    </a>
                                </div>
                            </div>
                            <div className={styles.socialIconsBoxMobile}>
                                <div>
                                    <a href="#">
                                        <img src={facebookMobile} alt="facebook" />
                                    </a>
                                    <a href="#">
                                        <img src={twitterMobile} alt="twitter" />
                                    </a>
                                    <a href="#">
                                        <img src={instaMobile} alt="instagram" />
                                    </a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
            <footer className={styles.footer}>
                <div className={styles.footerContainer}>
                    <div className={styles.footerFlex}>
                        <a href="/" className={styles.footerLogo}>
                            <img src={logo} alt="logotype" />
                        </a>
                        <div className={styles.socialIconsBox}>
                            {/* <a href="#">
                                <img src={facebookWhite} alt="facebook" />
                            </a>
                            <a href="#">
                                <img src={twitterWhite} alt="twitter" />
                            </a>
                            <a href="#">
                                <img src={instaWhite} alt="instagram" />
                            </a> */}
                        </div>
                        <a href="#register" className={styles.registrationLink}>
                            <img src={blueArrow} alt="toRegistration" />
                            <p>Registration form</p>
                        </a>
                    </div>
                    <div className={styles.footerLine}></div>
                    <div className={styles.corporation}>Copyright © 2021. All rights reserved.</div>
                </div>
            </footer>
            <Auth />
        </>
    );
};
