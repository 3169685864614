import moment from 'moment';
import { getToken } from '../../utils/accessToken';
import { makeRequest } from '../makeRequest';

export const getEvents = async () => {
    return new Promise(resolve => {
        makeRequest({
            url: `${process.env.REACT_APP_API_URL}/api/events`,
            method: 'GET',
            token: getToken(),
        }).then(r => {
            resolve(r.data);
        });
    });
};

type Event = {
    start: string;
    end: string;
    username: string;
    email: string;
};

export const createEvent = async ({ start, end, username, email }: Event) => {
    return new Promise(resolve => {
        makeRequest({
            url: `${process.env.REACT_APP_API_URL}/api/events`,
            method: 'POST',
            token: getToken(),
            body: JSON.stringify({
                start: start,
                end: end,
                username: username,
                email: email,
            }),
        }).then(res => {
            resolve(res.data);
        });
    });
};

type moveEventType = {
    start: string;
    end: string;
    id: string;
};

export const moveEvent = async ({ start, end, id }: moveEventType) => {
    return new Promise(resolve => {
        makeRequest({
            url: `${process.env.REACT_APP_API_URL}/api/events`,
            method: 'PUT',
            token: getToken(),
            body: JSON.stringify({
                start: start,
                end: end,
                id: id,
            }),
        }).then(res => {
            resolve(res.data);
        });
    });
};

export const GetNextEvent = async (email = '') => {
    return new Promise(resolve => {
        makeRequest({
            url: `${process.env.REACT_APP_API_URL}/api/events`,
            method: 'GET',
            token: getToken(),
        }).then(r => {
            let data = r?.data?.filter((ev: any) => {
                if (
                    moment(ev.start.dateTime).add(1, 'hour').isAfter(moment()) &&
                    ev?.attendees?.some((a: any) => a.email == email)
                )
                    return ev;
            });
            data?.sort(
                (a: any, b: any) =>
                    moment(a.start.dateTime).unix() - moment(b.start.dateTime).unix(),
            );

            if (data.length) resolve(data[0]);
            else resolve(null);
        });
    });
};

export const GetNextEventAdmin = async (email = '') => {
    return new Promise(resolve => {
        makeRequest({
            url: `${process.env.REACT_APP_API_URL}/api/events`,
            method: 'GET',
            token: getToken(),
        }).then(r => {
            let data = r.data.filter((ev: any) =>
                moment(ev.start.dateTime).add(1, 'hour').isAfter(moment()),
            );
            data.sort(
                (a: any, b: any) =>
                    moment(a.start.dateTime).unix() - moment(b.start.dateTime).unix(),
            );

            if (data.length) resolve(data[0]);
            else resolve(null);
        });
    });
};
