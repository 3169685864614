import { BrowserRouter as Router, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { PrivateRoute } from "./utils/ProtectedRoute";

import { Website } from "./modules/Landing";
import { userProfile } from "./modules/UserProfile";

const queryClient = new QueryClient();

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Route exact path="/">
          <Website />
        </Route>
        <Route path="/profile">
          <PrivateRoute Component={userProfile} />
        </Route>
      </Router>
    </QueryClientProvider>
  );
};
