import { Redirect } from 'react-router-dom';

import { Spinner } from '@components/Spinner';
import { useAuthorized } from './useAuthorized';
import { getToken } from './accessToken';
import { useJwt } from 'react-jwt';
import { ProfileForm } from '../modules/UserProfile/components/ProfileForm';
import { useRoleAuth } from './useRoleAuth';
import { getUser } from '@api/user';
import { useQuery } from 'react-query';

type Component = {
    Component: any;
    admin?: boolean;
};

export const PrivateRoute = ({ Component }: Component) => {
    const { authorized, loading } = useAuthorized();
    const { data: user } = useQuery('getUser', getUser);

    if (loading) {
        return <Spinner className="fixed top-0 left-0" />;
    }

    if (!authorized) return <Redirect to={'/?auth=login'} />;


    return user?.isAccountCompleted ? (
        <Component />
    ) : (
        <>
            <Component />
            <ProfileForm />
        </>
    );
};
