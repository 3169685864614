import { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useJwt } from 'react-jwt';
import { NavLink } from 'react-router-dom';

import { deleteToken, getToken } from '@utils/accessToken';
import { Titles } from './ titles';
import styles from './header.module.scss';
import { useQuery } from 'react-query';

import { getUser } from '@api/user';
import { isMobile } from 'react-device-detect';

import burger from './img/burger.svg';
import logo from './img/logo.svg';
import home from './images/home.svg';
import files from './images/files.svg';
import payment from './images/payment.svg';
import calendar from './images/calendar.svg';
import settings from './images/settings.svg';
import exit from './images/exit.svg';

export const Header = () => {
    const menu: any = useRef();
    const location = useLocation();

    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState();
    const { decodedToken } = useJwt(getToken());

    const { data } = useQuery('getUser', getUser, {
        refetchOnWindowFocus: true,
    });

    useEffect(() => {
        // @ts-ignore
        setTitle(Titles[location.pathname]);
    }, [decodedToken, decodedToken?.username, location]);

    const handleClick = useCallback(e => {
        if (menu.current && !menu?.current?.contains(e.target)) {
            setOpen(false);
        }
    }, []);

    useEffect(() => {
        open
            ? document.addEventListener('click', handleClick)
            : document.removeEventListener('click', handleClick);
    }, [open, handleClick]);

    if (isMobile) {
        return (
            <>
                <header className="w-full px-5 mt-5 h-10 flex justify-between items-center">
                    <div className="rounded-full h-10 w-10 flex justify-center items-center bg-primary text-white">
                        {data?.firstname?.charAt(0)}
                        {data?.lastname?.charAt(0)}
                    </div>
                    <img className="h-8" src={logo} alt="" />
                    <img onClick={() => setOpen(!open)} className="h-4" src={burger} alt="" />
                </header>
                <div
                    className={`w-full h-screen fixed bg-black bg-opacity-40 right-0 top-0 z-50 transition-all ${
                        !open && 'hidden'
                    }`}>
                    <div
                        className={`w-7/12 bg-white absolute top-0 h-screen pt-12  right-0 transition-all ${
                            !open && 'hidden'
                        }`}
                        ref={menu}>
                        <img className="w-10/12 mx-auto" src={logo} alt="" />
                        <ul className="space-y-7 pl-8 mt-14">
                            <li className="flex">
                                <NavLink
                                    onClick={() => setOpen(false)}
                                    exact
                                    to="/profile/dashboard"
                                    activeClassName={styles.active}
                                    className="flex text-base items-center">
                                    <img
                                        className="border border-grey-dark p-2.5 rounded-xl mr-3 w-4 h-4 box-content flex justify-center items-center"
                                        src={home}
                                        alt=""
                                    />
                                    Dashboard
                                </NavLink>
                            </li>
                            <li className="flex">
                                <NavLink
                                    onClick={() => setOpen(false)}
                                    to="/profile/calendar"
                                    activeClassName={styles.active}
                                    className="flex text-base items-center">
                                    <img
                                        className="border border-grey-dark p-2.5 rounded-xl mr-3 w-4 h-4 box-content flex justify-center items-center"
                                        src={calendar}
                                        alt=""
                                    />
                                    Calendar
                                </NavLink>
                            </li>
                            <li className="flex">
                                <NavLink
                                    onClick={() => setOpen(false)}
                                    to="/profile/files"
                                    activeClassName={styles.active}
                                    className="flex text-base items-center">
                                    <img
                                        className="border border-grey-dark p-2.5 rounded-xl mr-3 w-4 h-4 box-content flex justify-center items-center"
                                        src={files}
                                        alt=""
                                    />
                                    Files
                                </NavLink>
                            </li>
                            <li className="flex">
                                <NavLink
                                    onClick={() => setOpen(false)}
                                    to="/profile/payment"
                                    activeClassName={styles.active}
                                    className="flex text-base items-center">
                                    <img
                                        className="border border-grey-dark p-2.5 rounded-xl mr-3 w-4 h-4 box-content flex justify-center items-center"
                                        src={payment}
                                        alt=""
                                    />
                                    Payment
                                </NavLink>
                            </li>
                            <li className="flex">
                                <NavLink
                                    onClick={() => setOpen(false)}
                                    to="/profile/settings"
                                    activeClassName={styles.active}
                                    className="flex text-base items-center">
                                    <img
                                        className="border border-grey-dark p-2.5 rounded-xl mr-3 w-4 h-4 box-content flex justify-center items-center"
                                        src={settings}
                                        alt=""
                                    />
                                    Settings
                                </NavLink>
                            </li>
                            <li className="flex">
                                <a
                                    href="/"
                                    onClick={() => {
                                        deleteToken();
                                    }}
                                    className="flex text-base items-center">
                                    <img
                                        className="border border-grey-dark p-2.5 rounded-xl mr-3 w-4 h-4 box-content flex justify-center items-center"
                                        src={exit}
                                        alt=""
                                    />
                                    Exit
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        );
    }

    return (
        <header className={styles.header}>
            <div className={styles.title}>{title}</div>
            <div className={styles.user_info}>
                <p className="flex">{`${data?.firstname} ${data?.lastname}`}</p>
                <div className="h-10 w-10 rounded-full flex justify-center items-center text-white bg-primary ml-5">
                    {data && data?.firstName?.charAt(0)}
                    {data && data?.lastName?.charAt(0)}
                </div>
            </div>
        </header>
    );
};
