import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useQuery } from 'react-query';
import Slick from 'react-slick';

import { buyLessons, getTransactions, increaseBalance } from '@api/payment';
import { getUser } from '@api/user';

import { Spinner } from '@components/Spinner';
import { History } from './History';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './payment.module.scss';
import { currencies } from '@utils/currency';

const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
};

export const Payment = () => {
    const { search } = useLocation();
    const query = new URLSearchParams(search);

    const { data: user, refetch } = useQuery('getUser', () => getUser(), {
        refetchOnWindowFocus: true,
    });

    const { data: transactions } = useQuery('getTransactions', () => getTransactions(), {
        refetchOnWindowFocus: true,
    });

    useEffect(() => {
        let lessons = query.get('lessons');
        let title = query.get('title');
        let price = query.get('price');
        if (lessons && title && price)
            increaseBalance({
                lessons,
                title,
                price,
            }).then(
                () =>
                    (window.location.href = `${window.location.origin}${window.location.pathname}`),
            );
    }, []);

    const packages = useMemo(() => {
        return [
            {
                id: 1,
                title: 'Single class',
                color: '#FFAB40',
                quantity: '1',
                price: user?.rate,
                info: 'Buy one class at a time for maximum flexibility and minimal financial commitment.',
            },
            {
                id: 2,
                title: 'Five class package',
                color: '#04D3B7',
                quantity: '5',
                price: user?.rate * 5 * 0.975,
                info: 'Buy five classes and enjoy a discount of 2.5% on the price of a single class.',
            },
            {
                id: 3,
                title: 'Ten class package',
                color: '#BD9EFF',
                quantity: '10',
                price: user?.rate * 10 * 0.95,
                info: 'Buy ten classes for a discount of 5%. Larger discounts on more classes are available by negotiation. Please contact admin.',
            },
        ];
    }, [user?.rate]);

    if (!user) return <Spinner />;

    if (user?.rate == 0)
        return (
            <div className="text-center mt-20 text-xl">
                You account is not confirmed by Charles yet.
            </div>
        );

    return (
        <div className={styles.container}>
            {isMobile ? (
                <div className={styles.Slick}>
                    {packages.map(pack => (
                        <div key={pack.id} className={styles.items}>
                            <h2 className={styles.countLessons} style={{ background: pack.color }}>
                                {pack.title}
                            </h2>
                            <div className={styles.priceBlock}>
                                <span>{currencies[user.currency]}</span>
                                <p className={styles.price}>{pack.price.toFixed(2)}</p>
                            </div>
                            <div className={styles.itemsInfo}>
                                <p className={styles.Info}>{pack.info}</p>
                            </div>
                            <button
                                onClick={() =>
                                    buyLessons({
                                        price: pack.price,
                                        title: pack.title,
                                        lessons: pack.quantity,
                                        currency: user.currency,
                                    })
                                }
                                style={{ background: pack.color }}>
                                Buy
                            </button>
                        </div>
                    ))}
                </div>
            ) : (
                <div className={styles.itemsBox}>
                    {packages.map(pack => (
                        <div key={pack.id} className={styles.items}>
                            <h2 className={styles.countLessons} style={{ background: pack.color }}>
                                {pack.title}
                            </h2>
                            <div className={styles.priceBlock}>
                                <span>{currencies[user.currency]}</span>
                                <p className={styles.price}>{pack.price.toFixed(2)}</p>
                            </div>
                            <div className={styles.itemsInfo}>
                                <p className={styles.Info}>{pack.info}</p>
                            </div>
                            <button
                                onClick={() =>
                                    buyLessons({
                                        price: pack.price,
                                        title: pack.title,
                                        lessons: pack.quantity,
                                        currency: user.currency,
                                    })
                                }
                                style={{ background: pack.color }}>
                                Buy
                            </button>
                        </div>
                    ))}
                </div>
            )}
            <div className={styles.balance}>
                <h3 className={styles.balanceTitle}>Lessons in hand</h3>
                <div className={styles.balanceFlex}>
                    <p className={styles.countLessons}>{user?.balance}</p>
                    <p className={styles.balanceLesson}>lessons</p>
                </div>
            </div>
            <div className={styles.history}>
                <History transactions={transactions ?? []} />
            </div>
        </div>
    );
};
