import { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useQuery } from 'react-query';

import { setUserRate, takeAllPeople, deleteUser } from '@api/user';
import { UserRoles } from '../../../utils/roles';

import { Modal } from '../../../components/Modal';
import { Field } from '@uikit/Fields';

import styles from './admin.module.scss';
import { currencies } from '@utils/currency';
import { toast } from 'react-toastify';
import { EditModal } from './adminModals/editModal';
import { IUser } from 'types/dto';

export const Admin = () => {
    const { data: users, refetch: refetchUsers } = useQuery('getUsers', () => takeAllPeople(), {
        refetchOnWindowFocus: true,
    });

    const [currentUser, setCurrentUser] = useState<IUser | undefined>(undefined);

    const handleDeleteUser = (name: string, username: string) => {
        if (!confirm(`Are you sure want to delete ${name}?`)) return;
        if (!confirm(`Are you definitely sure want to delete ${name}?`)) return;
        deleteUser({
            username,
        }).then(() =>
            setTimeout(() => {
                refetchUsers();
            }, 100),
        );
    };

    const handleSetPrice = (username: string, values: any) => {
        if (newRate > 0)
            setUserRate({
                username,
                rate: values.rate,
                currency: values.rate,
            }).then(() =>
                setTimeout(() => {
                    refetchUsers();
                }, 100),
            );
    };

    const [showModal, setShowModal] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [selectedUser, setSelectedUser] = useState<IUser>();
    const [newRate, setNewRate] = useState(0);

    return (
        <div className={styles.container}>
            <Modal onClose={() => setShowModal(false)} show={showModal}>
                <div className={styles.ModalPrice}>
                    <img src="/peoplePhoto/default.svg" alt="" />
                    <div className={styles.modalName}>
                        {selectedUser?.firstname} {selectedUser?.lastname}
                    </div>
                    <p className={styles.priceText}>Price</p>
                    <Formik
                        initialValues={{
                            currency: 'USD',
                            rate: 0,
                        }}
                        onSubmit={async values => {
                            await setUserRate({
                                username: selectedUser?.username as string,
                                rate: values.rate,
                                currency: values.currency,
                            });
                            toast.success('Rate changed successfully');
                            refetchUsers();
                            setShowModal(false);
                        }}>
                        {({ errors, touched, setFieldValue }) => (
                            <Form>
                                <Field
                                    type="number"
                                    name="rate"
                                    placeholder={'0'}
                                    classnames={styles.input}
                                    setCurrency={value => setFieldValue('currency', value)}
                                />
                                <button className={styles.modalButton} type="submit">
                                    Save
                                </button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
            <Modal onClose={() => setShowEdit(false)} show={showEdit}>
                <EditModal
                    data={currentUser}
                    onClose={() => {
                        setShowEdit(false);
                        refetchUsers();
                    }}
                />
            </Modal>
            <div className={styles.navigation}>
                <div className={styles.navTitle}>
                    <div className={styles.points}>
                        <p className={styles.allUsers}>All Users</p>
                        <p className={styles.totalUsers}>{users?.length} total</p>
                        {/* <p className={styles.inactive}>2 inactive</p> */}
                    </div>
                    <div className={styles.additionalFeatures}>
                        {/* <p>
                            <img src="/magnifyingGlass.svg" alt="search" />
                        </p> */}
                    </div>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th className={styles.name}>Name</th>
                        <th className={styles.role}>Site role</th>
                        <th className={styles.price}>Price</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {users?.map((user: IUser) => {
                        return (
                            <tr key={`people__${user._id}`}>
                                <td className={styles.nameList}>
                                    <img src="/peoplePhoto/default.svg" />
                                    <div>
                                        <h2>
                                            {user.firstname
                                                ? user.firstname + ' ' + user.lastname
                                                : 'Not filled profile yet'}
                                        </h2>
                                        <p>{user?.nickname ? user.nickname : user.username}</p>
                                    </div>
                                    <p className={styles.active}>
                                        {/* inactive for {people.inactiveDays} days */}
                                    </p>
                                </td>
                                <td
                                    className={
                                        user.roles.includes('ADMIN') ? styles.admin : styles.regular
                                    }>
                                    {/* @ts-ignore */}
                                    <div>
                                        {user.roles.includes('ADMIN') ? 'Admin' : 'Regular user'}
                                    </div>
                                </td>
                                {user.rate == 0 ? (
                                    <td
                                        className={styles.setPrice}
                                        onClick={() => {
                                            setSelectedUser(user), setShowModal(true);
                                        }}>
                                        set a price
                                    </td>
                                ) : (
                                    <td className={styles.selectPrice}>
                                        {currencies[user.currency]} {user.rate} / hour
                                    </td>
                                )}
                                <td className={styles.deleteButton}>
                                    <img
                                        onClick={() =>
                                            handleDeleteUser(
                                                user.firstname + ' ' + user.lastname,
                                                user.username,
                                            )
                                        }
                                        src="/iconRemove.svg"
                                        alt="Remove"
                                    />
                                </td>
                                <td className={styles.edit}>
                                    <img
                                        onClick={() => {
                                            setCurrentUser(user);
                                            setShowEdit(true);
                                        }}
                                        src="/edit.svg"
                                        alt="edit"
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
