import { useMemo, useCallback, useRef, useState, useEffect } from 'react';
import FullCalendar, {
    DateSpanApi,
    DateSelectArg,
    EventDropArg,
    SlotLabelContentArg,
    CalendarApi,
} from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';

import { createEvent, getEvents, moveEvent } from '@api/events';

import moment from 'moment';
import { tz } from 'moment-timezone';
import { useQuery } from 'react-query';
import { useJwt } from 'react-jwt';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import { useSwipeable } from 'react-swipeable';

import { getToken } from '@utils/accessToken';
import { Spinner } from '@components/Spinner';
import './style.scss';
import { getUser } from '@api/user';
import CalendarNav from './nav';

const Calendar: React.FC = () => {
    let calendarRef: any = useRef();
    let calendarApi: CalendarApi = calendarRef.current?.getApi();
    const [activeDate, setActiveDate] = useState(moment());
    const [render, setRender] = useState(moment().day());

    useEffect(() => {
        calendarApi?.gotoDate(activeDate?.format());
    }, [activeDate, calendarApi]);

    const { decodedToken } = useJwt(getToken());
    const slotMinTime = `${tz('2021-06-17 07:00', 'Europe/London').tz(tz.guess()).hour()}:00`;
    const slotMaxTime = `${tz('2021-06-17 18:00', 'Europe/London').tz(tz.guess()).hour()}:00`;

    const {
        isLoading,
        isFetching,
        refetch: refetchCalendarEvents,
        data: calendarEvents,
    } = useQuery('getEvents', getEvents, {
        refetchOnWindowFocus: false,
    });

    const { data: user } = useQuery('getName', getUser, {
        refetchOnWindowFocus: false,
    });

    const handleAllowSelect = (selectionInfo: DateSpanApi) => {
        const start = moment(selectionInfo.startStr);
        const end = moment(selectionInfo.endStr);

        if (start.isBefore(moment())) return false;
        if (moment.duration(end.diff(start)).asHours() > 1) return false;
        return true;
    };

    const handleSelect = useCallback(
        async (info: DateSelectArg) => {
            setTimeout(() => {
                if (window.confirm('Do you want to book a lesson?')) {
                    createEvent({
                        start: info.startStr,
                        end: info.endStr,
                        username: `${user?.firstName} ${user?.lastName}`,
                        email: decodedToken?.username ?? 'Unknown',
                    }).then(r => {
                        refetchCalendarEvents();
                    });
                }
                info.view.calendar.unselect();
            }, 100);
        },
        [decodedToken?.username, refetchCalendarEvents, user],
    );

    const handleEventDrop = async (event: EventDropArg) => {
        if (
            moment
                .duration(moment(event.event.endStr).diff(moment(event.event.startStr)))
                .asHours() < 1
        ) {
            toast.warn('Sorry, the lesson duration can be only 1 hour.', {
                position: toast.POSITION.BOTTOM_CENTER,
            });
            return false;
        }
        if (moment(event.event.startStr).isBefore(moment())) {
            event.revert();
            toast.warn("Sorry, you can't move event to the past.", {
                position: toast.POSITION.BOTTOM_CENTER,
            });
            return false;
        }
        if (moment(event.event.startStr).diff(moment(), 'hours') < 8) {
            event.revert();
            toast.warn("Sorry, but you can't book a lesson so close to the present moment.", {
                position: toast.POSITION.BOTTOM_CENTER,
            });
            return false;
        }
        if (window.confirm('Are you sure you want to move this lesson?')) {
            await moveEvent({
                start: event.event.startStr,
                end: event.event.endStr,
                id: event.event._def.publicId,
            });
            refetchCalendarEvents();
        } else {
            event.revert();
            calendarApi.unselect();
        }
    };

    const handleClick = (data: DateClickArg) => {
        calendarApi.select(data.dateStr, moment(data.dateStr).add(1, 'hour').format());
    };

    const events = useMemo(
        () =>
            // @ts-ignore
            calendarEvents?.map((event: any) => {
                const isMyEvent = !!event.attendees?.find(
                    (p: any) => p.email === decodedToken?.username,
                );
                const result = {
                    id: event?.id,
                    start: event?.start?.dateTime,
                    end: event?.end?.dateTime,
                    title: isMyEvent ? 'Your accent class' : 'Busy',
                    editable:
                        isMyEvent && !(moment(event?.start?.dateTime).diff(moment(), 'hours') < 8),
                    className: isMyEvent ? 'my' : 'busy',
                };
                return result;
            }) ?? [],
        [calendarEvents, decodedToken?.username],
    );

    const headersHandler = (args: any) => {
        if (isMobile) {
            return (
                <p className="font-medium text-base font-inter">
                    {moment(args.date).format('dddd')} {moment(args.date).format('D')}{' '}
                    {moment(args.date).format('MMMM')} {moment(args.date).format('YYYY')}
                </p>
            );
        } else {
            return (
                <p className="font-medium text-xs">
                    {moment(args.date).format('ddd')}
                    <br />
                    <span className="text-xl">{moment(args.date).format('D')}</span>
                </p>
            );
        }
    };

    const handleResize = (event: any) => {
        event.revert();
    };

    const handleSlotLabel = (args: SlotLabelContentArg) => {
        return (
            <span className="font-inter" style={{ fontSize: `${isMobile ? '10px' : '12px'}` }}>
                {moment(args.date).format('h')} {moment(args.date).format('A')}
            </span>
        );
    };

    const handlers = useSwipeable({
        onSwipedRight: eventData => {
            if (activeDate.isSame(moment(), 'day')) {
                return false;
            }
            setActiveDate(date => date.subtract(1, 'day'));
            calendarApi.prev();
            setRender(activeDate.day());
        },
        onSwipedLeft: eventData => {
            if (activeDate.isSame(moment().add(6, 'day'), 'day')) {
                return false;
            }
            setActiveDate(date => date.add(1, 'day'));
            calendarApi.next();
            setRender(activeDate.day());
        },
        delta: 100,
        preventDefaultTouchmoveEvent: true,
    });

    // TODO delete event and add it to the balance
    return (
        <div
            className="calendar relative bg-white text-black rounded-sm"
            {...handlers}
            style={{ touchAction: 'pan-y' }}>
            <CalendarNav activeDate={activeDate} setActiveDate={setActiveDate} render={render} />
            {isFetching || isLoading ? <Spinner /> : null}
            <FullCalendar
                ref={calendarRef}
                plugins={[interactionPlugin, timeGridPlugin]}
                initialView={'timeGridDay'}
                events={events}
                nowIndicator
                selectable
                selectMinDistance={2}
                eventOverlap={false}
                selectOverlap={false}
                slotMinTime={slotMinTime}
                slotMaxTime={slotMaxTime}
                allDaySlot={false}
                editable={false}
                height={'85vh'}
                selectAllow={handleAllowSelect}
                eventResize={handleResize}
                eventDrop={handleEventDrop}
                dayHeaderContent={headersHandler}
                dateClick={handleClick}
                customButtons={{
                    myCustomButton: {
                        text: `${tz.guess()}`,
                    },
                }}
                headerToolbar={{
                    start: 'myCustomButton',
                    center: '',
                    end: '',
                }}
                slotLabelContent={handleSlotLabel}
                select={handleSelect}
                viewClassNames={`${isFetching || isLoading ? 'opacity-0' : null}`}
            />
        </div>
    );
};

export default Calendar;
