import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { signUp } from '@api/auth';

import { Field } from '@uikit/Fields';
import { Button } from '@uikit/Button';

import { ErrorMessage, Field as Input, getIn } from 'formik';

export const SignUp = () => {
    const [error, setError] = useState('');

    return (
        <div className="z-50 w-470 px-14 py-12 bg-white">
            <p className="text-2xl">
                Signup or{' '}
                <Link
                    className="underline text-primary hover:text-primary hover:opacity-70 transition-all"
                    to="/?auth=login">
                    Login
                </Link>
            </p>
            <p className="text-error mt-2">{error}</p>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    passwordConfirmation: '',
                    secureCode: '',
                    currency: '',
                }}
                onSubmit={async (values, { setValues }) => {
                    signUp({
                        username: values.email,
                        password: values.password,
                        secureCode: values.secureCode,
                    }).then(res => {
                        setError(res.error);
                        !res.status &&
                            setValues({
                                email: values.email,
                                password: values.password,
                                passwordConfirmation: values.passwordConfirmation,
                                secureCode: '',
                                currency: '',
                            });
                    });
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Enter correct email').required('Required'),
                    password: Yup.string()
                        .min(4, 'Password length should be more 4 symbols')
                        .required('Required'),
                    passwordConfirmation: Yup.string().oneOf(
                        [Yup.ref('password')],
                        'Passwords should be same',
                    ),
                    secureCode: Yup.string().required('Enter secure code please'),
                })}>
                {({ errors, touched, setFieldValue }) => (
                    <Form className="space-y-11 mt-11">
                        <Field
                            name="email"
                            placeholder="Email"
                            type="email"
                            errors={errors}
                            touched={touched}
                        />
                        <Field
                            name="password"
                            type="password"
                            placeholder="Password"
                            errors={errors}
                            touched={touched}
                        />
                        <Field
                            name="passwordConfirmation"
                            type="password"
                            placeholder="Confirm password"
                            errors={errors}
                            touched={touched}
                        />
                        <Field
                            name="secureCode"
                            type="password"
                            placeholder="Secure code"
                            errors={errors}
                            touched={touched}
                        />
                        <Input
                            as="select"
                            name="currency"
                            className="w-full h-full border-b p-4 border-grey-light outline-none text-grey-dark pl-2 pr-1 font-medium">
                            <option value="USD">$</option>
                            <option value="EUR">€</option>
                            <option value="GBP">£</option>
                        </Input>
                        <div className="flex justify-between items-center">
                            <Button title="Signup" />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
